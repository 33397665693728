.invalid_field {
    border: 2px solid rgba(238, 42, 30, 1) !important;
}
.disabled {
    background-color: #fefcfc !important;
    cursor: not-allowed !important;
}
.input_group_append {
    position: absolute;
    right: 0.25em;
    top: 0.1em;
    font-size: 1.2em;
    padding: 0.8em 1em;
    border-radius: 0 0.5em 0.5em 0;
    z-index: 2;
}