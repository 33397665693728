.sidebar {
    position: relative;
    display: none;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    padding: 0 1em;
    z-index: 1;
    &.active {
        @media (max-width: 992px) {
            display: block;
        }
    }
    > ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 100%;
        flex: 0 1 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        -webkit-margin-before: 0;
        margin-block-start: 0;
        -webkit-margin-after: 0;
        margin-block-end: 0;
        -webkit-margin-start: 0;
        margin-inline-start: 0;
        -webkit-margin-end: 0;
        margin-inline-end: 0;
        -webkit-padding-start: 0;
        padding-inline-start: 0;
        > li {
            text-align: center;
            > a {
                padding-top: 1.714em;
                font-size: 0.875em;
                font-weight: 500;
                color: #00264f;
                position: relative;
                line-height: 5.071em;
                padding-left: 1.143em;
                display: flex;
                align-items: center;
                span {
                    transition: all 0.3s;
                }
                img,
                svg {
                    font-size: 2.143em;
                    line-height: normal;
                    margin-right: 1.367em;
                    path {
                        transition: opacity 0.3s;
                    }
                }
                &:hover {
                    text-decoration: none;
                    span {
                        background: -webkit-gradient(
                            linear,
                            left top,
                            right top,
                            from(#f68616),
                            to(#ee2a1e)
                        );
                        background: linear-gradient(
                            90deg,
                            #f68616 0%,
                            #ee2a1e 100%
                        );
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    svg path:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
            > ul {
                display: none;
                width: 100%;
                padding-left: 6.688em;
                li {
                    position: relative;
                    &:last-child {
                        margin-bottom: 0.75em;
                    }
                    &.serverTitle {
                        margin-bottom: 1em;
                        // color: #02b7ab;
                        font-weight: 400;
                        font-size: 1.25em;
                        text-align: center;
                    }
                    a {
                        display: block;
                        color: #00264f;
                        font-size: 0.875em;
                        line-height: 4em;
                        text-align: left;
                        font-family: 'Gilroy';
                        font-weight: 400;
                        &:hover {
                            text-decoration: none;
                            background: -webkit-gradient(
                                linear,
                                left top,
                                right top,
                                from(#f68616),
                                to(#ee2a1e)
                            );
                            background: linear-gradient(
                                90deg,
                                #f68616 0%,
                                #ee2a1e 100%
                            );
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    &.active {
                        &::before {
                            content: '';
                            background: #f68616 0%;
                            background: -webkit-gradient(
                                linear,
                                left bottom,
                                left top,
                                from(#f68616),
                                to(#ee2a1e)
                            );
                            background: linear-gradient(
                                0deg,
                                #f68616 0%,
                                #ee2a1e 100%
                            );
                            border-radius: 0.375em;
                            width: 0.188em;
                            position: absolute;
                            top: 0.688em;
                            right: 0;
                            bottom: 0.688em;
                        }
                        a {
                            span {
                                background: -webkit-gradient(
                                    linear,
                                    left top,
                                    right top,
                                    from(#f68616),
                                    to(#ee2a1e)
                                );
                                background: linear-gradient(
                                    90deg,
                                    #f68616 0%,
                                    #ee2a1e 100%
                                );
                                -webkit-background-clip: text;
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
            &.active {
                &:not(.disabled) {
                    > a {
                        svg path:nth-child(2) {
                            opacity: 1;
                        }
                        span {
                            background: -webkit-gradient(
                                linear,
                                left top,
                                right top,
                                from(#f68616),
                                to(#ee2a1e)
                            );
                            background: linear-gradient(
                                90deg,
                                #f68616 0%,
                                #ee2a1e 100%
                            );
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        &::before {
                            content: '';
                            background: #f68616 0%;
                            background: -webkit-gradient(
                                linear,
                                left bottom,
                                left top,
                                from(#f68616),
                                to(#ee2a1e)
                            );
                            background: linear-gradient(
                                0deg,
                                #f68616 0%,
                                #ee2a1e 100%
                            );
                            border-radius: 0.429em;
                            width: 0.857em;
                            position: absolute;
                            top: 1.714em;
                            left: -1.643em;
                            bottom: 1.714em;
                        }
                    }
                    > ul {
                        display: block;
                    }
                }
            }
            &:not(.active) {
                > a {
                    padding-bottom: 1.714em;
                }
            }
            &:not(:last-child) {
                border-bottom: 0.063em solid #e4e4e4;
            }
        }
    }
}
:global(body:not([data-sidebar-full='true'])) {
    .sidebar {
        > ul {
            > li {
                > a {
                    padding-bottom: 1.714em;
                }
                &.active {
                    &:not(.disabled) {
                        > ul {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .sidebar {
        min-width: 7.813em;
        max-width: 7.813em;
        margin-top: 2.219em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        padding: 0;
        min-height: 100vh;
        &::before {
            content: '';
            top: -2.219em;
            left: 100%;
            width: 14.563em;
            min-height: 1000vh;
            height: 105%;
            position: absolute;
            -webkit-box-shadow: inset 0.938em 0.938em 0.625em -0.313em #e0e0e0,
                0.625em 0 1.25em 0 #e0e0e0;
            box-shadow: inset 0.938em 0.938em 0.625em -0.313em #e0e0e0,
                0.625em 0 1.25em 0 #e0e0e0;
            border-radius: 6.25em 0 0 0;
        }
        > ul {
            > li {
                > a {
                    padding-left: 0;
                    padding-bottom: 1.714em;
                    display: block;
                    -webkit-box-align: unset;
                    -ms-flex-align: unset;
                    align-items: unset;
                    line-height: normal;
                    img,
                    svg {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 0.533em;
                    }
                }
                > ul {
                    position: absolute;
                    left: 100%;
                    top: 3.25em;
                    width: 14.563em;
                    padding-left: 0;
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            padding-left: 1.714em;
                        }
                    }
                }
                &.active {
                    &:not(.disabled) {
                        > a {
                            &::before {
                                left: -0.5em;
                            }
                        }
                    }
                }
            }
        }
    }
    :global(body:not([data-sidebar-full='true'])) {
        .sidebar {
            &::before {
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
    .sidebar {
        min-width: 9.688em;
        max-width: 9.688em;
        margin-top: 2.219em;
        > ul {
            > li {
                > ul {
                    top: 3.25em;
                    width: 14.563em;
                }
            }
        }
    }
}
@media (min-width: 992px) and (min-width: 1200px) {
    .sidebar {
        &::before {
            top: -2.219em;
            width: 14.563em;
        }
    }
}
