.container-fluid {
    padding: 0;

    // TODO: Check this, taken from bootstrap
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-grow: 1;
    position: relative;
    @media (min-width: 992px) {
        overflow: hidden;
    }

    &::before {
        content: '';
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-box-shadow: inset 0 0.938em 0.938em -0.938em #e0e0e0;
        box-shadow: inset 0 0.938em 0.938em -0.938em #e0e0e0;
        z-index: -1;
    }

    > .content {
        flex-grow: 1;
        position: relative;
        &.overflowHide {
            @media (max-width: 992px) {
                overflow: hidden;
            }
        }
        @media (max-width: 992px) {
            width: 100%;
        }
        > .container {
            padding-top: 2em;
            padding-bottom: 6em;
            @media (max-width: 992px) {
                max-width: unset;
                padding-bottom: 0;
            }
            &.hidden {
                display: none;
            }
        }
    }
}
:global(body:not([data-sidebar-full='true'])) {
    .container-fluid {
        &::before {
            left: 0;
        }
    }
}
:global(body[data-sidebar-full='true']) {
    .container-fluid {
        & > .content {
            @media (min-width: 992px) {
                padding-left: 15.813em;
                width: calc(100% - 15.813em);
            }
            & > .container {
                padding-top: 2em;
                @media (min-width: 992px) {
                    padding-top: 5.469em;
                }
            }
        }
        &:before {
            @media (min-width: 1200px) {
                left: 24.251em;
            }
            @media (min-width: 992px) {
                left: 22.376em;
            }
        }
    }
}
@media (min-width: 992px) {
    .container-fluid {
        > .content {
            > .container {
                padding-top: 5.469em;
            }
        }
    }
}
@media (min-width: 992px) {
    :global(body:not([data-sidebar-full='true'])) {
        .container-fluid {
            &::before {
                left: 7.813em;
                -webkit-box-shadow: inset 0.938em 0.938em 0.625em -0.313em #e0e0e0,
                    0.625em 0 1.25em 0 #e0e0e0;
                box-shadow: inset 0.938em 0.938em 0.625em -0.313em #e0e0e0,
                    0.625em 0 1.25em 0 #e0e0e0;
                border-radius: 6.25em 0 0 0;
            }
        }
    }
}
@media (min-width: 1200px) {
    :global(body:not([data-sidebar-full='true'])) {
        .container-fluid {
            &::before {
                left: 9.688em;
            }
        }
    }
}

