.invalid_field {
    border: 2px solid rgba(238, 42, 30, 1) !important;
}
.checkbox {
    position: absolute;
    left: 0;
    top: 0;
    border: 0.063em solid #00264f; // 1px
    border-radius: 0.5em; // 8px
    background-color: #fefcfc;
    width: 1.875em; // 30px
    height: 1.875em; // 30px
    transition: background 0.2s;
    &.checked {
        background-color: #00264f;
        font-size: 0.563em; // 9px
        // padding-top: 0.889em; // 8px
        // padding-left: 0.889em; // 8px
        width: 3.333em; // 30px
        height: 3.333em; // 30px
        border-radius: 0.889em; // 8px
        border: 0.111em solid #00264f; // 1px
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 9px;
            width: auto;
            path {
                fill: aliceblue;
            }
        }
    }
}
