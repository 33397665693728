.invalid_field .text {
    border: 2px solid rgba(238, 42, 30, 1) !important;
}
.select_hide {
    opacity: 0;
    height: 0;
    padding: 0;
    -webkit-appearance: none;
    &:disabled {
        opacity: 0;
        height: 0;
        padding: 0;
        -webkit-appearance: none;
    }
    option {
        display: none !important;
    }
}
.carret {
    position: absolute;
    right: 1.5em;
    top: 1.375em;
}