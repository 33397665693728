/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
    @if is-direction($value)==false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }

    $conversion-map: (to top: bottom,
        to top right: bottom left,
        to right top: left bottom,
        to right: left,
        to bottom right: top left,
        to right bottom: left top,
        to bottom: top,
        to bottom left: top right,
        to left bottom: right top,
        to left: right,
        to left top: right bottom,
        to top left: bottom right);

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - $value;
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
    $is-keyword: index((to top,
                to top right,
                to right top,
                to right,
                to bottom right,
                to right bottom,
                to bottom,
                to bottom left,
                to left bottom,
                to left,
                to left top,
                to top left),
            $value);
    $is-angle: type-of($value)=="number"and index("deg""grad""turn""rad", unit($value));

    @return $is-keyword or $is-angle;
}

@mixin linear-gradient($direction, $color-stops...) {

    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: linear-gradient($direction, $color-stops);
}

@mixin text-linear-gradient($direction, $color-stops...) {

    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }

    background: linear-gradient($direction, $color-stops);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin border-linear-gradient($direction, $color-stops...) {

    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }

    border-image-source: linear-gradient($direction, $color-stops);
    border-image-slice: 1;
}