/*------- Checkmark ---------*/
@keyframes draw_circle {
	0% {
		stroke-dasharray: 0,330;
		stroke-dashoffset: 0;
		opacity: 1;
	}
	80% {
		stroke-dasharray: 330,330;
		stroke-dashoffset: 0;
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes draw_check {
	0% {
		stroke-dasharray: 49,80;
		stroke-dashoffset: 48;
		opacity: 0;
	}
	50% {
		stroke-dasharray: 49,80;
		stroke-dashoffset: 48;
		opacity: 1;
	}
	100% {
		stroke-dasharray: 130,80;
		stroke-dashoffset: 48;
	}
}
/*---------- Cross ----------*/
@keyframes draw_first_line {
	0% {
		stroke-dasharray: 0,56;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 0,56;
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dasharray: 56,330;
		stroke-dashoffset: 0;
	}
}
@keyframes draw_second_line {
	0% {
		stroke-dasharray: 0,55;
		stroke-dashoffset: 1;
	}
	50% {
		stroke-dasharray: 0,55;
		stroke-dashoffset: 1;
	}
	100% {
		stroke-dasharray: 55,0;
		stroke-dashoffset: 70;
	}
}
@keyframes alert_sign_bounce {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	50% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
	}
}
.svg-box {
	display: inline-block;
	position: relative;
	width: 150px;
}
.green-stroke {
	stroke: #7CB342;
}
.red-stroke {
	stroke: rgba(238, 42, 30, 1);
}
.yellow-stroke {
	stroke: #FFC107;
}
.circular {
	circle.path {
		stroke-dasharray: 330;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		opacity: 0.4;
		animation: draw_circle 0.7s ease-out forwards;
	}
}
.checkmark {
	stroke-width: 6.25;
	stroke-linecap: round;
	position: absolute;
	top: 56px;
	left: 49px;
	width: 52px;
	height: 40px;
	path {
		animation: draw_check 1s ease-out;
	}
}
.cross {
	stroke-width: 6.25;
	stroke-linecap: round;
	position: absolute;
	top: 54px;
	left: 54px;
	width: 40px;
	height: 40px;
	.first-line {
		animation: draw_first_line 0.7s ease-out forwards;
	}
	.second-line {
		animation: draw_second_line 0.7s ease-out forwards;
	}
}
.alert-sign {
	stroke-width: 6.25;
	stroke-linecap: round;
	position: absolute;
	top: 40px;
	left: 68px;
	width: 15px;
	height: 70px;
	animation: alert_sign_bounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	.dot {
		stroke: none;
		fill: #FFC107;
	}
}
