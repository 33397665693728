@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_utilities';

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
@import '../styles/_gradient';

$default-gradian-deg: 90deg;
$default-gradian-colors: #f68616 0%, #ee2a1e 100%;

.navbar {
    padding: 0.438em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    @include media-breakpoint-down(md) {
        padding: 1.5em;
        max-width: 100%;
    }

    .navbar_inner {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .navbar_brand {
            font-family: Roboto, sans-serif;
            font-size: 2em;
            color: #fff;
            flex: 0 1 11em;
            img {
                height: auto;
                width: 100%;
            }
            @include media-breakpoint-up(lg) {
                margin-left: 0.781em;
            }
            @include media-breakpoint-down(sm) {
                display: flex;
                padding: 0;
                height: 1em;
                flex: 0 1 6.5em;
            }
            > span {
                vertical-align: middle;
                margin-left: 0.4em;
                > span {
                    font-weight: $font-weight-bold;
                    color: #04faed;
                }
            }

            @include media-breakpoint-down(md) {
                span {
                    display: none;
                }
            }

            &:hover {
                color: #fff;
            }
        }
        .navbar_toggler {
            display: flex;
            border: 0;
            order: 2;
            align-self: center;
            @include media-breakpoint-down(md) {
                padding: 0.35em 0.75em;
            }
            @include media-breakpoint-up(lg) {
                transform: translateY(-60%) translateX(-50%) rotate(45deg);
                padding: 3em;
                position: absolute;
                left: 50%;
                background: #031921;
                border-radius: 0.5em;
                align-self: flex-start;
                order: unset;
                .navbar_toggler_icon {
                    display: none;
                }
            }
            svg {
                height: 16px;
                width: 26px;
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    left: 55%;
                    bottom: 0;
                    right: 0;
                    top: 65%;
                    transform: translateY(-20%) translateX(20%) rotate(-45deg);
                    }
                path {
                    fill: #fff;
                }
            }
            &:focus {
                outline: none;
            }
        }
        .nav_extra {
            display: flex;
            align-self: flex-start;
            margin-left: auto;
            @include media-breakpoint-down(md) {
                align-self: center;
            }
            .nav_lang {
                display: flex;
                margin-right: 2em;
                @include media-breakpoint-down(md) {
                    display: flex;
                    margin-right: 0.5em;
                }

                > a {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 0.05em;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
                .langItem {
                    &:hover {
                        font-weight: 600;
                    }
                    .flag svg {
                        margin-right: 0.5em;
                        border: 1px solid rgb(0, 0, 0, 15%);
                        background-size: cover;
                        width: 1.7em;
                    }
                }
            }
            .nav_register {
                font-size: 0.875em;
                font-weight: $font-weight-semi-bold;
                padding: 1.143em 1.643em;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                margin-right: 0.714em;
                justify-content: center;
                width: unset;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            .nav_user {
                &:not(:disabled):not(.disabled):active {
                    border-radius: 0;
                }

                border-radius: 0;
                color: #031921;
                padding: 1.1em 1.643em;
                font-size: 0.875em;
                font-weight: $font-weight-semi-bold;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                width: unset;
                line-height: 1.142em;
                @include media-breakpoint-up(lg) {
                    background: #04faed;
                    border: 0.071em solid #04faed;
                }

                @include media-breakpoint-down(md) {
                    border: 0;
                    color: #fff;
                    background: unset;
                    width: auto;
                    box-shadow: unset;
                    padding: 0;
                    > span {
                        display: none;
                    }
                }
            }
        }
    }
}
.navigation {
    display: none;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding-left: 1.5em;
    padding-right: 1.5em;
    &.open_navigation {
        display: flex;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 8.188em;
        padding-right: 8.063em;
    }
    @include media-breakpoint-down(md) {
        padding-top: 6.625em;
    }

    background: #02131a;
    color: #fff;
    h4 {
        font-size: 1.5em;
        font-weight: $font-weight-regular;
        margin-bottom: 1.375em;
        @include media-breakpoint-down(md) {
            &:first-of-type {
                margin-top: 1.333em;
            }
        }
    }
    .navigation_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        position: relative;
        .nav_user {
            border-radius: 0;
            color: #031921;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #04faed;
            border: 0.071em solid #04faed;
            padding: 0.5em 0;
            line-height: 1em;
        }
        .nav_register {
            padding: 0.5em 0;
        }
        .links {
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            padding: 0;
            @include media-breakpoint-between(sm, md) {
                flex-wrap: wrap;
            }
            @include media-breakpoint-down(md) {
                flex-direction: column;
                margin-bottom: 2em;
            }
            @include media-breakpoint-up(lg) {
                &:first-of-type {
                    margin-bottom: 3.875em;
                }
            }
            li {
                flex: 1;
                @include media-breakpoint-between(sm, md) {
                    flex: 0 1 50%;
                    margin-bottom: 2em;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 1em;
                }
                @include media-breakpoint-up(md) {
                    flex: 0 1 33.3333%;
                }
                
                a {
                    display: flex;
                    background: #021820;
                    color: #fff;
                    font-size: 1.07em;
                    align-items: center;
                    border-radius: 0.563em;
                    font-weight: $font-weight-regular;
                    padding-left: 0.438em;
                    padding-top: 0.25em;
                    padding-bottom: 0.25em;
                    @include media-breakpoint-up(lg) {
                        &:nth-child(1) {
                            margin-right: 1.625em;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        span span {
                            text-decoration: underline;
                        }
                    }
                    img {
                        display: block;
                        margin-right: 0.938em;
                        max-width: 6.875em;
                    }
                    span {
                        flex: 1;
                        i {
                            margin-top: 0.688em;
                            display: block;
                            @include text-linear-gradient(
                                $default-gradian-deg,
                                $default-gradian-colors
                            );
                        }
                    }
                }
            }
        }
        .sub_links {
            display: flex;
            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 2em;
            }
            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 5em;
                right: 0;
            }
            li {
                display: flex;
                @include media-breakpoint-up(lg) {
                    margin-right: 2.813em;
                }

                a {
                    font-size: 0.875em;
                    color: #fff;
                }
            }
        }
    }
}
