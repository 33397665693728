.icon {
    cursor: pointer;
    g[opacity='0'],
    circle[opacity='0'],
    path[opacity='0'] {
        transition: opacity 0.3s;
    }
    &:hover {
        g[opacity='0'],
        circle[opacity='0'],
        path[opacity='0'] {
            opacity: 1;
        }
    }
}
