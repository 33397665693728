.btn.btn-outline-primary {
    position: relative;
    font-size: 0.875em;
    font-weight: 600;
    border: unset;
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='100%25' height='100%25' preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3ClinearGradient x1='-20.8960364%25' y1='50.0004403%25' x2='65.6589743%25' y2='-7.02565236%25' id='linearGradient-1'%3E%3Cstop stop-color='%23F68616' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23EE2A1E' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle' x='0' y='0' width='100%25' height='100%25' fill='transparent' rx='5' stroke='url(%23linearGradient-1)' stroke-width='1' stroke-linejoin='round' %3E%3C/rect%3E%3C/svg%3E");
    width: 100%;
    background-size: 100% 100%;
    transition: gradient 0.2s;
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    &:not(:hover) > span {
        background: -webkit-linear-gradient(left, #f68616 0%, #ee2a1e 100%);
        background: linear-gradient(90deg, #f68616 0%, #ee2a1e 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1em;
    }
}

.navbar {
    height: 5.5em;
    padding: 0 1em;
    .navbar_brand {
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        svg {
            width: 3.375em;
            height: 2.4375em;
        }
    }
    .navbar_text {
        margin-right: 1.778em;
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#f68616),
            to(#ee2a1e)
        );
        background: linear-gradient(90deg, #f68616 0%, #ee2a1e 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.125em;
        font-weight: 500;
        &.navbar_text_xs {
            text-align: center;
            padding-top: 1em;
            padding-bottom: 1em;
            border-bottom: 1px solid #e9ecef;
        }
    }
    .navbar_collapse {
        .navbar_nav {
            margin-right: 2em;
            .nav_item .nav_link {
                font-size: 0.875em;
                font-weight: 700;
                color: #00264f;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }
        .btn {
            margin: 0 2em 0 0;
            width: 8%;
            min-width: fit-content;
            max-width: fit-content;
            padding-left: 27px;
            padding-right: 27px;
            padding-top: 16px;
            padding-bottom: 16px;

            border: solid 1px #ee2a1e;
            border-width: thin;
            border-radius: 5px;
            background: unset;
            &:hover {
                -webkit-text-fill-color: unset;
                -webkit-background-clip: unset;
                background-clip: unset;
                background: #f68616 0%;
                background: linear-gradient(
                    90deg,
                    #f68616 0%,
                    #ee2a1e 50%
                );
            }
        }
    }
    .navbar_toggler {
        flex: 0 1 10%;
        height: 30%;
        svg {
            height: 100%;
            width: auto;
        }
        &:active,
        &:focus {
            outline: none;
        }
    }
    .dropdown {
        &.nav_lang {
            .nav_lang_item {
                font-weight: 600;
                letter-spacing: 0.05em;
            }
            .langItem {
                &:hover {
                    font-weight: 600;
                }
                .flag svg {
                    margin-right: 0.5em;
                    border: 1px solid rgb(0 0 0 / 15%);
                    background-size: cover;
                    width: 1.7em;
                }
            }
        }
        .user_media {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
            font-size: 1.1em;
            .media_body {
                font-size: 0.875em;
                line-height: 1.1714em;
                color: #999999;
                margin-right: 1.1714em;
                .login_name {
                    font-weight: 500;
                    color: initial;
                    display: block;
                    &.blur {
                        max-width: 169px;
                        position: relative;
                        overflow: hidden;
                        &:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            height: 100%;
                            width: 10px;
                            background: linear-gradient(
                                90deg,
                                rgba(139, 167, 32, 0) 0%,
                                rgba(255, 255, 255, 1) 100%
                            );
                        }
                    }
                }
            }
            svg {
                background-color: #e4e4e4;
                border-radius: 1.125em;
                width: 48px;
                height: 48px;
                padding: 15px;
            }
            & + .dropdown_menu {
                margin: 0.625em 0 0 0;
                padding: 0.25em 0;
                border: 0;
                border-radius: 0.813em;
                background-color: #ffffff;
                -webkit-box-shadow: 0 0 2.5em 0 #ebebeb;
                box-shadow: 0 0 2.5em 0 #ebebeb;
                a {
                    color: #00264f;
                    padding: 1em 1.143em;
                    font-size: 0.875em;
                }
            }
        }
    }
    .navbar_toggler {
        border: 0;
        padding: 0;
        line-height: normal;
        font-size: 1em;
        min-width: 1.75em;
        text-align: right;
        .navbar_toggler_icon {
            background: unset;
            width: auto;
            height: auto;
        }
    }
}

@media (min-width: 992px) {
    .navbar {
        height: 5.969em;
        padding: 0 3em 0 0;
        .navbar_brand {
            -ms-flex-preferred-size: 9.688em;
            flex-basis: 9.688em;
        }
    }
}
