.notification__container-icon {
    flex-basis: 20%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .notification__container-icon-inner {
        box-shadow: #d8d0ee 0 0 10px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg path {
            fill: #322e55;
        }
    }
}

.notification__container {
    flex-basis: 80%;
    padding: 17px 0px 17px 0px;
    display: inline-block;

    .notification__title {
        color: #322e55;
        font-weight: 600;
        font-size: 14px;
    }
    .notification__message {
        color: #322e55;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0;
    }
}

.notification__container--success {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 3px;

    .notification__container-icon {
        border-left: 4px solid #02b7ab;
        color: #02b7ab;
    }
}

.notification__container--default {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 3px;

    .notification__container-icon {
        border-left: 4px solid #02b7ab;
        color: #02b7ab;
    }
}

.notification__container--danger {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 3px;

    .notification__container-icon {
        border-left: 4px solid #02b7ab;
        color: #02b7ab;
    }
}

.notification__container--info {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 3px;

    .notification__container-icon {
        border-left: 4px solid #02b7ab;
        color: #02b7ab;
    }
}

.notification__container--warning {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 3px;

    .notification__container-icon {
        border-left: 4px solid #02b7ab;
        color: #02b7ab;
    }
}
