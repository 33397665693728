.toggle {
    .input {
        display: none;
    }
    .label {
        display: inline-block;
        margin-bottom: 0;
        color: #00264f;
        font-weight: 500;
        text-align: left;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        font-size: 1.2em;
        &.switchRightFixed {
            display: flex;
            justify-content: space-between;
        }
        &.checked span {
            padding-left: 1.256em;
            &::after{
            background: -webkit-gradient(linear, left top, right top, from(#f68616), to(#ee2a1e));
            background: linear-gradient(90deg, #f68616 0%, #ee2a1e 100%);
            }
        }
        span {
            display: inline-block;
            vertical-align: middle;
            border: 0;
            border-radius: 0.630em;
            background-color: #ffffff;
            -webkit-box-shadow: 0 0 0.374em 0px #b9b9b9;
            box-shadow: 0 0 0.374em 0px #b9b9b9;
            width: 2.407em;
            height: 1.259em;
            -webkit-transition: padding 0.2s, background 0.2s;
            transition: padding 0.2s, background 0.2s;
            line-height: normal;
            padding-left: 0.148em;
            padding-right: 0.148em;
            padding-top: 0.111em;
            padding-bottom: 0.111em;
            margin-left: 1.148em;
            cursor: pointer;
            color: #a4a4a4;
            font-size: 1.35em;
            &::after {
                content: '';
                display:block;
                background-color: #a4a4a4;
                width: 27px;
                height: 27px;
                border-radius: 50%;
            }
        }
        &.labelSsSuffix {
            span {
                margin-left: 0;
                margin-right: 1.148em;
            }
        }
    }
}
@media (max-width: 767.98px) {
	.toggle {
        .label {
            &::after {
                margin-left: auto;
            }
        }
    }
}
